import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
   {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'ODEX',
    lpAddresses: {
      // 25437: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      33698: '0x172DB922E69Da830c611eA9c0F558Cfe34E1275f',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wocai,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'ODEX-OCAI LP',
    lpAddresses: {
      // 25437: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      33698: '0x96A3C335A5B3198facB1fe447781635c9C7bc82C',
    },
    token: serializedTokens.odex,
    quoteToken: serializedTokens.wocai,
  },
  // {
  //   pid: 3,
  //   // v1pid: 2,
  //   lpSymbol: 'BCUSD-OCAI LP',
  //   lpAddresses: {
  //     25437: '0xc8EdeC730CC6aBb57c213c840633071164357357',
  //     33698: '0x3101ca7d8e255F1f1bB31Cb0183599d9Ba37da31',
  //   },
  //   token: serializedTokens.bcusd,
  //   quoteToken: serializedTokens.wocai,
  // },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  // {
  //   pid: 4,
  //   // v1pid: 3,
  //   lpSymbol: 'BCUSDT-OCAI LP',
  //   lpAddresses: {
  //     25437: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
  //     33698: '0x98e7E75EeB1a1d5E594ecE6Fa25B596b6C0b16dF',
  //   },
  //   token: serializedTokens.bcusdt,
  //   quoteToken: serializedTokens.wocai,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'ETH-OCAI LP',
  //   lpAddresses: {
  //     25437: '',
  //     33698: '0x8c213E3170bC941b56eD76eC2FAE884C79074bCE',
  //   },
  //   token: serializedTokens.eth,
  //   quoteToken: serializedTokens.wocai,
  // },


  
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
