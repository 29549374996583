function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  display: grid;\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { grid, flexbox } from "styled-system";
import Box from "@odex/uikit/src/components/Box/Box";
var Grid = styled(Box).withConfig({
    componentId: "sc-3a264173-0"
})(_templateObject(), flexbox, grid);
export default Grid;
