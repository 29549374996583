function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { scales, variants } from "@odex/uikit/src/components/Button/types";
var _obj;
export var scaleVariants = (_obj = {}, _defineProperty(_obj, scales.MD, {
    height: "48px",
    padding: "0 24px"
}), _defineProperty(_obj, scales.SM, {
    height: "32px",
    padding: "0 16px"
}), _defineProperty(_obj, scales.XS, {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px"
}), _obj);
var _obj1;
export var styleVariants = (_obj1 = {}, _defineProperty(_obj1, variants.PRIMARY, {
    background: "#185ADA",
    color: "#ffffff"
}), _defineProperty(_obj1, variants.SECONDARY, {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: "#185ADA",
    boxShadow: "none",
    color: "#185ADA",
    ":disabled": {
        backgroundColor: "transparent"
    }
}), _defineProperty(_obj1, variants.TERTIARY, {
    backgroundColor: "tertiary",
    boxShadow: "none",
    color: "#ffffff"
}), _defineProperty(_obj1, variants.SUBTLE, {
    backgroundColor: "textSubtle",
    color: "backgroundAlt"
}), _defineProperty(_obj1, variants.DANGER, {
    backgroundColor: "failure",
    color: "white"
}), _defineProperty(_obj1, variants.SUCCESS, {
    backgroundColor: "success",
    color: "white"
}), _defineProperty(_obj1, variants.TEXT, {
    backgroundColor: "transparent",
    color: "#185ADA",
    boxShadow: "none"
}), _defineProperty(_obj1, variants.LIGHT, {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none"
}), _obj1);
